import Vue from "vue";
import RestService from "@/common/rest.service";
import {
    APP_READY,
    AVATAR_UPLOAD,
    DEPOSIT_REQUEST,
    DEPOSITS_GET,
    ATTACHMENT_UPLOAD,
    DOCUMENT_UPLOAD,
    DOCUMENTS_GET,
    LOGIN,
    LOGOUT,
    OTP_ACTIVATE,
    OTP_CREATE,
    OTP_DISABLE,
    PASSWORD_CHANGE,
    PASSWORD_RESET,
    PASSWORD_RESTORE,
    PERSONAL_UPDATE,
    REGISTER,
    TRANSFER_REQUEST,
    WITHDRAWAL_REQUEST,
    WITHDRAWALS_GET,
    REFRESH_USER,
    DASHBOARD_BALANCE,
    GENERATE_INVOICE,
    DOWNLOAD_INVOICE,
    UPLOAD_CONFIRMATION,
    CANCEL_WITHDRAWAL,
    PRE_LOGIN,
    DEPOSIT_OTP,
    GET_HISTORY_ASSET, GTE_AUTOTRADER_CONFIG, GET_BANKS_LIST
} from "@/store/actions.type";
import {
    USER,
    USER_PERSONAL,
    MANAGERS,
    WITHDRAWALS,
    DOCUMENTS,
    DEPOSITS,
    NOTIFICATION,
    TOKEN,
    DEPOSIT_ADDRESS,
    OTP_ENABLE_DATA,
    MODAL,
    TRANSACTION,
    NOTIFICATION_TRANSACTION,
    DEPOSIT_TRANSACTION,
    SYMBOL,
    PSP_LIST,
    QUESTIONS,
    OTHER_PSPS,
    DASHBOARD_LOGIN,
    DASHBOARD_STATS,
    DASHBOARD_BALANCE_CHART,
    CFD_ORDER,
    MODAL_FULLSCREEN,
    BANK_DEPOSIT,
    ACTIVE_CURRENCY,
    SET_TRANSACTION_ID,
    WITHDRAWAL_LIST,
    SET_ROBOT_AI,
    SET_AUTOTRADER_CONFIG,
    SET_BANKS_VARIABLES, CURRENCIES, NOTIFICATION_VERIFICATION
} from '@/store/mutations.type'
import {timeToString, localStorage} from "@/common/helpers";
import {INITIAL_DASHBOARD, KYC_DOCUMENTS, REST_ENDPOINT} from "@/common/config"

const state = {
    loggedIn: false,
    token: null,
    user: {
        // firstname: ' ',
        // lastname: ' ',
        fullname: ' ',
        avatar: '',
        created: 0,
        otp: 0,
        enable_withdrawal: 1,
        enable_chat: 1,
        min_deposit: 0
    },
    managers: [],
    deposits: [],
    withdrawals: [],
    documents: [],
    depositAddress: '',
    depositTransaction: {
        id: 0,
        status: 0
    },
    otpEnableData: {
        qr: '',
        base32: ''
    },
    pspList: [],
    withdrawalList: [],
    questions: [],
    otherPsps: [],
    dashboardData: {
        logins: {
            count: 0,
            rows: {
                browser: "",
                id: 0,
                ip: "",
                name: "",
                time: 0,
                user_id: 0
            }
        },
        stats: {
            amount: 1,
            cnt_profit: 0,
            profit: 0,
            deposits: 0,
            currency: '',
            currency_dep: 0
        },
        balance_chart: [],
    },
    bankDeposit: {
        id: '',
        memo: '',
        misc: ''
    },
    activeCurrency: "USD",
    isoCountry: [{
        country: '',
        iso: ''
    }],
    isActiveOtp: false,
    transactionId: 0,
    robotIsActive: localStorage.getItem('robotIsActive') === 'true' || false,
    historyAssets: [],
    autoTraderConfig: {},
    verificationState: true,
    bankConfig: [],
    banksConfigData: [],
}

const getters = {
    loggedIn() {
        return state.loggedIn
    },

    user() {
        return state.user
    },

    managers() {
        return state.managers
    },

    deposits() {
        return state.deposits
    },

    withdrawals() {
        return state.withdrawals
    },

    documents() {
        return state.documents
    },

    depositAddress() {
        return state.depositAddress
    },

    depositTransaction() {
        return state.depositTransaction
    },

    otpEnableData() {
        return state.otpEnableData
    },

    pspList() {
        return state.pspList
    },

    withdrawalList() {
        return state.withdrawalList
    },

    questions() {
        return state.questions
    },

    otherPsps() {
        return state.otherPsps
    },

    dashboardData() {
        return state.dashboardData
    },

    bankDeposit() {
        return state.bankDeposit
    },

    activeCurrency() {
        return state.activeCurrency
    },

    robotIsActive() {
        return state.robotIsActive
    },

    historyAssets() {
        return state.historyAssets
    },
    autoTraderConfig() {
        return state.autoTraderConfig
    },

    verificationState() {
        return state.verificationState
    },

    bankConfig() {
        return state.bankConfig
    },

    banksConfigData() {
        return state.banksConfigData
    }
}

const actions = {
    [REFRESH_USER]() {
        RestService.get('/user')
            .then(user => {
                this.commit(USER, user)
            })

    },
    [APP_READY]() {
        if (state.token === undefined || state.token === 'undefined' || state.token === null || state.token === 'null') {
            localStorage.removeItem('token')

            RestService.get('/symbols/full')
                .then(symbols => {
                    for (let i in symbols) {
                        this.commit(SYMBOL, symbols[i])
                    }
                })
                .catch(() => {})
        } else {
            if (window.location.pathname === '/register'){
                window.location.pathname = '/'
            }
            RestService.token(state.token)
            RestService.get('/user', {}, () => {
                localStorage.removeItem('token')
                RestService.token('')

                RestService.get('/symbols/full')
                    .then(symbols => {
                        for (let i in symbols) {
                            this.commit(SYMBOL, symbols[i])
                        }
                    })
                    .catch(() => {})
            })
                .then(user => {
                    if (!user) {
                        localStorage.removeItem('token')
                        return
                    }

                    this.commit(USER, user)
                    this.dispatch(GET_BANKS_LIST)
                })
        }
    },

    [PRE_LOGIN](context, data) {
        RestService.get('/otp', {email: data.email, password: data.password})
            .then(otp => {
                otp.otp ? this.commit(MODAL, {otp: true}) : this.dispatch(LOGIN, data)
            })
    },

    [LOGIN](context, data) {
        RestService.post('/auth', data)
            .then(user => {
                this.commit(USER, user)
                this.commit(MODAL, {
                    fullscreen: true,
                })

                if (INITIAL_DASHBOARD) {
                    this.commit(MODAL_FULLSCREEN, {
                        page: "dashboard"
                    })
                }
            })
    },

    [PASSWORD_RESTORE](context, data) {
        RestService.post('/user/password', data)
            .then(() => {
                this.commit(NOTIFICATION, 'E-mail with the reset link has been sent to your e-mail address. Please check your inbox.')
            })
    },

    [PASSWORD_RESET](context, data) {
        RestService.post('/password/reset', {
            password: data.password,
            hash: localStorage.getItem('hash')
        })
            .then(() => {
                this.commit(NOTIFICATION, 'Password has been changed')
                this.commit(MODAL, {
                    newPassword: false,
                    login: true
                })
                window.history.pushState({}, '', '/')
            })
    },

    [LOGOUT]() {
        localStorage.removeItem('token')
        RestService.token('')

        this.commit(LOGOUT)
    },

    [REGISTER](context, data) {
        data.affiliate_id = localStorage.getItem('referrer') || 0
        RestService.post('/user', data)
            .then(() => {
                this.commit(NOTIFICATION, 'Account has been registered. You may login now')

                this.commit(MODAL, {
                    register: false,
                    login: true
                })

                this.dispatch(LOGIN, data)
            })
    },

    [PERSONAL_UPDATE](context, user) {
        RestService.put('/user', user)
            .then(() => {
                this.commit(NOTIFICATION, 'Personal details have been updated')

                let nUser = {
                    ...state.user
                }

                for (let n in user) {
                    nUser[n] = user[n]
                }

                nUser.fullname = nUser.firstname + ' ' + nUser.lastname
                nUser.jwt_token = localStorage.getItem('token')

                this.commit(USER, nUser)
            })
    },

    [PASSWORD_CHANGE](context, pass) {
        RestService.put('/user/password', pass)
            .then(() => {
                this.commit(NOTIFICATION, 'Password has been changed. Please re-login with new password.')
            })
    },

    [DEPOSITS_GET]() {
        RestService.get('/transactions/deposits')
            .then(deposits => {
                this.commit(DEPOSITS, deposits.rows || [])
            })
    },

    [WITHDRAWAL_REQUEST](context, data) {
        data.balance = 'balance'
        RestService.post('/transactions/withdrawals', data)
            .then(() => {
                this.commit(NOTIFICATION, 'Withdrawal request has been sent')
                this.dispatch(WITHDRAWALS_GET)
            })
    },

    [WITHDRAWALS_GET]() {
        RestService.get('/transactions/withdrawals')
            .then(withdrawals => {
                this.commit(WITHDRAWALS, withdrawals.rows || [])
            })
    },

    [ATTACHMENT_UPLOAD](context, data) {
        RestService.post('/support/messages/file', data)
            .then(() => {
                // this.commit(NOTIFICATION, 'Attachment uploaded')
            })
    },


    [DOCUMENT_UPLOAD](context, data) {
        RestService.post('/kyc/documents', data)
            .then(() => {
                this.commit(NOTIFICATION, 'Document successfully uploaded')
                this.dispatch(DOCUMENTS_GET)
            })
    },

    [DOCUMENTS_GET]() {
        RestService.get('/kyc/documents')
            .then(documents => {
                this.commit(DOCUMENTS, documents.rows || [])
            })
    },

    [TRANSFER_REQUEST](context, data) {
        return RestService.post('/transactions/transfers', data)
            .then(() => {
                this.commit(NOTIFICATION, 'Transfer has been processed')
            })
    },

    [DEPOSIT_REQUEST](context, data) {
        switch (data.psp) {
            case 'cryptocurrency':
                this.commit(DEPOSIT_ADDRESS, '')
                return RestService.get(`/psp/cryptocurrency/user/wallet`, {
                    currency: data.currency,
                    psp: 'cryptocurrency',
                    account_id: this.getters.activeAccount.id,
                    product: 'cfd',
                    user_id: state.user.id
                })
                    .then(res => {
                        if (res.wallet) {
                            this.commit(DEPOSIT_ADDRESS, res.wallet)
                        }
                    }).catch(err => Promise.reject(err))
            case 'nax':
                this.commit(DEPOSIT_ADDRESS, '')
                return RestService.get('/psp/nax/wallet', {
                    currency: data.currency,
                    psp: 'nax',
                    account_id: this.getters.activeAccount.id,
                    product: 'cfd'
                })
                    .then(res => {
                        if (res.wallet) {
                            this.commit(DEPOSIT_ADDRESS, res.wallet)
                        }
                    }).catch(err => Promise.reject(err))
            case 'perfectmoney':
            case 'webmoney':
            case 'paymentwall':
            case 'realeasypay':
            case 'cascading':
            case 'finanic':
            case 'betatransfer':
            case 'praxis':
            case 'prmoney':
            case 'starbill_usd':
            case 'starbill_eur':
            case 'paypound':
            case 'kryptova':
            case 'walletix':
            case 'walletcomru':
            case 'payzon':
            case 'bitexbit':
            case 'paystudio':
            case 'stripe':
            case 'settlepay_usd':
            case 'settlepay_eur':
            case 'settlepay_kzt':
            case 'garrypay':
            case 'texcent':
            case 'wellex':
            case 'villpay':
            case 'payhub':
            case 'ppay':
            case 'pinikle':
            case 'enot':
            case 'decard':
            case 'p2p_bank':
            case 'milkypay':
            case 'todapay':
            case 'prmoney_ru':
            case 'prmoney_eur':
            case 'payretailers':
            case 'dikeshield':
            case 'dikeshield_smartpay':
            case 'dikeshield_2':
            case 'alfakit':
            case 'momentex':
            case 'betspacemoney':
            case 'betspacemoney_card':
            case 'paycos':
            case 'storsunrise':
            case 'transfertum':
            case 'paypecker':
            case 'ampay':
            case 'nqpays':
            case 'solidpayment':
                RestService.post('/psp/request', {
                    currency: data.currency,
                    psp: data.psp,
                    amount: data.amount,
                    account_id: this.getters.realAccount.id,
                    product: 'cfd',
                })
                    .then(data => {
                        let form, win, script, url
                        switch (data.type) {
                            case 'post':
                                form = document.createElement('form')
                                url = decodeURIComponent(data.data.url)

                                form.setAttribute('action', url)
                                form.setAttribute('method', 'post')
                                form.setAttribute('hidden', 'true')
                                form.setAttribute('target', '_blank')

                                for (let n in data.data.params) {
                                    let input = document.createElement('input')
                                    input.setAttribute('type', 'text')
                                    input.setAttribute('name', n)
                                    input.setAttribute('value', data.data.params[n])

                                    form.appendChild(input)
                                }

                                document.body.appendChild(form);
                                form.submit()
                                document.body.removeChild(form)

                                break
                            case 'get':
                                url = decodeURIComponent(data.data.url)
                                win = window.open(url, '_blank')

                                if (win) {
                                    win.focus()
                                }
                                break
                            case 'script':
                                url = decodeURIComponent(data.data.url)
                                script = document.createElement('script')
                                script.src = url
                                document.getElementsByTagName('head')[0].appendChild(script);
                                break
                        }
                    })
                break
            case 'payepo':
            case 'payepo_world':
                RestService.post('/psp/request', {
                    currency: data.currency,
                    psp: data.psp,
                    amount: data.amount,
                    account_id: this.getters.realAccount.id,
                    product: 'cfd',
                })
                    .then(res => {
                        let form, win, script, url
                        switch (res.type) {
                            case 'post':
                                form = document.createElement('form')
                                url = res.data.url

                                form.setAttribute('action', url)
                                form.setAttribute('method', 'post')
                                form.setAttribute('hidden', 'true')
                                form.setAttribute('target', '_blank')

                                for (let n in res.data.params) {
                                    let input = document.createElement('input')
                                    input.setAttribute('type', 'text')
                                    input.setAttribute('name', n)
                                    input.setAttribute('value', res.data.params[n])

                                    form.appendChild(input)
                                }

                                document.body.appendChild(form);
                                form.submit()
                                document.body.removeChild(form)

                                break
                            case 'get':
                                url = res.data.url
                                win = window.open(url, '_blank')

                                if (win) {
                                    win.focus()
                                }
                                break
                            case 'script':
                                url = res.data.url
                                script = document.createElement('script')
                                script.src = url
                                document.getElementsByTagName('head')[0].appendChild(script);
                                break
                        }
                    })
                break
            case 'billing_ru':
            case 'billing_world':
            case 'honeypay':
            case 'square':
                RestService.post('/psp/request', {
                    currency: data.currency,
                    psp: data.psp,
                    amount: data.amount.trim(),
                    account_id: this.getters.realAccount.id,
                    product: 'cfd',
                })
                    .then(data => {
                        let form, win, script, url
                        switch (data.type) {
                            case 'post':
                                form = document.createElement('form')
                                url = decodeURIComponent(data.data.url)
                                form.setAttribute('action', url)
                                form.setAttribute('method', 'get')
                                form.setAttribute('hidden', 'true')
                                form.setAttribute('target', '_blank')

                                for (let n in data.data.params) {
                                    let input = document.createElement('input')
                                    input.setAttribute('type', 'text')
                                    input.setAttribute('name', n)
                                    input.setAttribute('value', data.data.params[n])

                                    form.appendChild(input)
                                }

                                document.body.appendChild(form);
                                form.submit()
                                document.body.removeChild(form)

                                break
                            case 'get':
                                url = decodeURIComponent(data.data.url)
                                win = window.open(url, '_blank')

                                if (win) {
                                    win.focus()
                                }
                                break
                            case 'script':
                                script = document.createElement('script')
                                url = decodeURIComponent(data.data.url)
                                script.src = url
                                document.getElementsByTagName('head')[0].appendChild(script);
                                break
                        }
                    })
                break
            case 'payonera':
            case 'checkoutchamp':
            case 'bmopay':
            case 'pinpay':
            case 'alwayspay_visa':
            case 'chargemoney':
            case 'alwayspay_master':
                RestService.post('/psp/request', {
                    currency: data.currency,
                    psp: data.psp,
                    amount: data.amount,
                    account_id: this.getters.realAccount.id,
                    product: 'cfd',
                    card: data.card ? data.card : ''
                })
                    .then(data => {
                        let form, win, script, url
                        switch (data.type) {
                            case 'post':
                                form = document.createElement('form')
                                url = decodeURIComponent(data.data.url)
                                form.setAttribute('action', url)
                                form.setAttribute('method', 'post')
                                form.setAttribute('hidden', 'true')
                                form.setAttribute('target', '_blank')

                                for (let n in data.data.params) {
                                    let input = document.createElement('input')
                                    input.setAttribute('type', 'text')
                                    input.setAttribute('name', n)
                                    input.setAttribute('value', data.data.params[n])

                                    form.appendChild(input)
                                }

                                document.body.appendChild(form);
                                form.submit()
                                document.body.removeChild(form)

                                break
                            case 'get':
                                url = decodeURIComponent(data.data.url)
                                win = window.open(url, '_blank')

                                if (win) {
                                    win.focus()
                                }
                                break
                            case 'script':
                                script = document.createElement('script')
                                url = decodeURIComponent(data.data.url)
                                script.src = url
                                document.getElementsByTagName('head')[0].appendChild(script);
                                break
                        }
                    })
                break
            case 'bank':
                RestService.post('/transactions/deposits', {
                    amount: data.amount.trim(),
                    account_id: this.getters.realAccount.id,
                    currency: data.currency,
                    product: 'cfd'
                })
                    .then(() => {
                        this.commit(NOTIFICATION, 'Please contact your account manager for bank details')
                        this.dispatch(DEPOSITS_GET)
                    })
                break
            case 'cc-ext':
                RestService.post('/transactions/deposits', data)
                    .then(transaction => {
                        // this.dispatch(DEPOSITS_GET)
                        this.commit(SET_TRANSACTION_ID, transaction.id)
                    })
                break
            case 'cc-ext2':
                RestService.post('/transactions/deposits', data)
                    .then(transaction => {
                        this.commit(DEPOSIT_TRANSACTION, transaction)
                        // this.dispatch(DEPOSITS_GET)
                        this.commit(SET_TRANSACTION_ID, transaction.id)
                    })
                break
            case 'razorpay':
                RestService.post('/psp/request', {
                    psp: data.psp,
                    amount: data.amount.trim(),
                    currency: data.currency,
                    account_id: this.getters.realAccount.id,
                    product: 'cfd'
                })
                    .then(data => {
                        let rzp = new window.Razorpay(data.data.params)

                        rzp.open()

                        this.dispatch(DEPOSITS_GET)
                    })
                break
        }
    },

    [DEPOSIT_OTP](context, otpCode){
        RestService.post(`/transactions/deposits/${state.transactionId}/otp`,{
            otp: otpCode
        }).then(() => {
            this.commit(NOTIFICATION, "Thank you! Financial manager will contact you in order to verify the transfer")
        })
          .catch(() => {})
    },

    [GENERATE_INVOICE](context, data) {
        RestService.post('/transactions/deposits/bank', data)
            .then(ans => {
                this.commit(BANK_DEPOSIT, ans)
                this.dispatch(DEPOSITS_GET)
            })
    },

    [DOWNLOAD_INVOICE](context, data) {
        return new Promise((resolve, reject) => {
            let tokens = data.tokens
            RestService.get('/banks/invoice/' + data.depositId, {
                ...tokens
            })
                .then(r => {
                    let anchor = document.createElement('a')
                    anchor.href = REST_ENDPOINT + '/kyc/document/' + r.file + '?download'
                    anchor.download = r.file

                    anchor.target = '_self'

                    setTimeout(() => {
                        anchor.click()
                        resolve()
                    }, 0)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    [UPLOAD_CONFIRMATION](context, data) {
        RestService.post('/transactions/deposits/bank/' + state.bankDeposit.id, data)
            .then(() => {
                this.commit(NOTIFICATION, 'Document successfully uploaded')
                this.dispatch(DEPOSITS_GET)
            })
    },

    [AVATAR_UPLOAD](context, data) {
        RestService.post('/avatar', data)
            .then(ans => {
                this.commit(NOTIFICATION, 'Photo successfully uploaded')
                this.commit(USER, {
                    avatar: ans.file
                })
            })
    },

    [OTP_CREATE]() {
        RestService.post('/otp')
            .then(data => {
                this.commit(OTP_ENABLE_DATA, data)
            })
    },

    [OTP_ACTIVATE](context, data) {
        RestService.put('/otp', {
            code: data.code
        })
            .then(() => {
                this.commit(NOTIFICATION, '2FA protection has been activated')
                this.commit(USER, {
                    otp: 1
                })
            })
    },

    [OTP_DISABLE](context, data) {
        RestService.post('/otp/remove', {
            code: data.code
        })
            .then(() => {
                this.commit(NOTIFICATION, '2FA protection has been disabled')

                this.dispatch(OTP_CREATE)
                this.commit(USER, {
                    otp: 0
                })
            })
    },

    [DASHBOARD_BALANCE](context, time) {
        RestService.get('/dashboard/balance_chart/', {count: time})
            .then(balance => {
                this.commit(DASHBOARD_BALANCE_CHART, balance)
            })
    },

    [CANCEL_WITHDRAWAL](context, id) {
        RestService.put(`/transactions/withdrawals/${id}/cancel`)
            .then(ans => {
                if (ans) {
                    let withdrawalsNew = this.getters.withdrawals.filter(w => w.id !== id)
                    this.commit(WITHDRAWALS, withdrawalsNew)
                }
            })
    },

    [GET_HISTORY_ASSET](context, symbols) {
        for (let i = 0; i < symbols.length; i++) {
            (i => {
                RestService.get('/quotes/' + symbols[i] + '/history', {
                    frame: 60,
                    count: 49,
                    offset: 0,
                    group: 'crypto'
                })
                    .then(data => {
                        Vue.set(state.historyAssets, symbols[i], data)
                    })
            })(i)
        }
    },

    [GTE_AUTOTRADER_CONFIG]() {
        RestService.get('/autotrader')
            .then(data => {
                this.commit(SET_AUTOTRADER_CONFIG, data)
            })
    },

    [SET_AUTOTRADER_CONFIG](context, data) {
        RestService.post('/autotrader', {...data})
            .then(ans => {
                this.commit(SET_AUTOTRADER_CONFIG, ans)
            })
    },

    [GET_BANKS_LIST]() {
        RestService.get('/banks/list')
            .then((ans) => {
                const enabledBanks = ans.filter(bank => bank.enable)
                Vue.set(state, 'bankConfig', enabledBanks)
                state.banksConfigData = enabledBanks.map(i => ({ alias: i.alias, currency: i.currency }))
            })
    }
}

const mutations = {
    [TOKEN](context, token) {
        RestService.token(token)

        localStorage.setItem('token', token)

        state.token = token
    },

    [USER](context, user) {
        for (let n in user) {
            Vue.set(state.user, n, user[n])
        }

        this.commit(TOKEN, user.jwt_token)

        if (user.id) {
            state.loggedIn = true
        }

        RestService.get('/psp/list')
            .then(list => {
                this.commit(PSP_LIST, list)
            })

        RestService.get('/withdrawal_config/list')
            .then(list => {
                this.commit(WITHDRAWAL_LIST, list)
            })

        RestService.get('/kyc/questions')
            .then(questions => {
                this.commit(QUESTIONS, questions)
            })

        RestService.get('/currencies',)
            .then(curr => {
                this.commit(CURRENCIES, curr)
            })

        RestService.get('/psp/other')
            .then(otherPsps => {
                this.commit(OTHER_PSPS, otherPsps)
            })

        RestService.get('/auth/logins')
            .then(logins => {
                this.commit(DASHBOARD_LOGIN, logins)
            })

        RestService.get('/dashboard/stats')
            .then(stats => {
                this.commit(DASHBOARD_STATS, stats)
            })

        this.dispatch(DOCUMENTS_GET)
        this.dispatch(GTE_AUTOTRADER_CONFIG)
        this.dispatch(DASHBOARD_BALANCE, 1 * 24)
    },

    [USER_PERSONAL](context, user) {
        for (let n in user) {
            Vue.set(state.user, n, user[n])
        }
    },

    [CFD_ORDER](context, order) {
        if (order.time_close > 0) {
            let created = false

            for (const acc in state.dashboardData.stats.accounts) {
                state.dashboardData.stats.accounts[acc].account_id === order.account_id ? created = true : ''
            }

            if (!state.dashboardData.stats.accounts) {
                Vue.set(state.dashboardData.stats, 'accounts', [])
            }

            if (!created) {
                state.dashboardData.stats.accounts.push({
                    account_id: order.account_id,
                    cnt_total: 0,
                    amount: 0,
                    profit: 0,
                    cnt_profit: order.profit > 0 ? "1" : "0"
                })
            }

            state.dashboardData.stats.amount = 0
            state.dashboardData.stats.profit = 0

            for (const acc in state.dashboardData.stats.accounts) {
                if (state.dashboardData.stats.accounts[acc].account_id === order.account_id) {
                    state.dashboardData.stats.accounts[acc].cnt_total += 1
                    state.dashboardData.stats.accounts[acc].amount += order.margin
                    state.dashboardData.stats.accounts[acc].profit += order.profit
                }

                state.dashboardData.stats.amount += state.dashboardData.stats.accounts[acc].amount
                state.dashboardData.stats.profit += state.dashboardData.stats.accounts[acc].profit
            }

            if (order.profit > 0) {
                state.dashboardData.stats.cnt_profit = state.dashboardData.stats.cnt_profit ? parseInt(state.dashboardData.stats.cnt_profit) + 1 : 1
            }
        }
    },

    [LOGOUT]() {
        state.loggedIn = false
    },

    [MANAGERS](context, managers) {
        state.managers = managers
    },

    [DEPOSITS](context, deposits) {
        Vue.set(state.dashboardData.stats,'deposits', 0)

        for (let i in deposits) {
            deposits[i].timeCreatedString = timeToString(deposits[i].time_created * 1000)
            if(deposits[i].status === 1 && deposits[i].balance === "balance") {
                Vue.set(state.dashboardData.stats,'deposits', deposits[i].amount_usd + state.dashboardData.stats.deposits)
            }
        }

        if (deposits) {
            deposits.reverse()
        }

        Vue.set(state, 'deposits', deposits || [])
    },

    [WITHDRAWALS](context, withdrawals) {
        for (let i in withdrawals) {
            withdrawals[i].timeCreatedString = timeToString(withdrawals[i].time_created * 1000)
        }

        if (withdrawals) {
            withdrawals.reverse()
        }

        Vue.set(state, 'withdrawals', withdrawals)
    },

    [DOCUMENTS](context, documents) {
        for (let i in documents) {
            documents[i].timeCreatedString = timeToString(documents[i].time_created * 1000)
        }

        if (documents) {
            documents.reverse()
        }

        Vue.set(state, 'documents', documents)
        let seachedDocuments = [],
            docs = documents,
            status = ''


        KYC_DOCUMENTS.forEach((value, i, key) => {
            let docsOfType = docs.filter(doc => doc.type === key[i])
            docsOfType.sort((a, b) => a.id - b.id)
            let lastDocOfType = docsOfType[docsOfType.length - 1]
            status = lastDocOfType ? lastDocOfType.status : -1
            let resultingDoc = {status}
            seachedDocuments.push(resultingDoc)
        })

        let successfully = 0

        seachedDocuments.forEach(el => {
            if (el.status === 1) {
                successfully++
            }
        })

        if  (state.user.enable_force_verification) {
            state.verificationState = KYC_DOCUMENTS.length === successfully
        }

        if (!state.verificationState && JSON.parse(localStorage.getItem('new-design'))) {
            this.commit(NOTIFICATION_VERIFICATION, 'Verify identity to secure your account')
        }
    },

    [DEPOSIT_ADDRESS](context, address) {
        state.depositAddress = address
    },

    [DEPOSIT_TRANSACTION](context, transaction) {
        Vue.set(state, 'depositTransaction', transaction)
    },

    [SET_TRANSACTION_ID](context, transactionId){
        Vue.set(state, 'transactionId', transactionId)
    },

    [OTP_ENABLE_DATA](context, data) {
        state.otpEnableData = data
    },

    [TRANSACTION](context, data) {
        if ((data.status === 1 || data.status === 2) && (data.type === 1 || data.type === 2)) {
            this.commit(NOTIFICATION_TRANSACTION, data)
        }

        if (state.depositTransaction.id === data.id) {
            this.commit(DEPOSIT_TRANSACTION, data)
        }

        this.dispatch(DEPOSITS_GET)
    },

    [PSP_LIST](context, data) {
        state.pspList = data
    },

    [WITHDRAWAL_LIST](context, data) {
        state.withdrawalList = data
    },

    [QUESTIONS](context, data) {
        state.questions = data
    },

    [OTHER_PSPS](context, data) {
        state.otherPsps = data
    },

    [DASHBOARD_LOGIN](context, login) {
        login.rows.reverse()
        state.dashboardData.logins = login
    },

    [DASHBOARD_STATS](context, stats) {
        state.dashboardData.stats = stats
    },

    [DASHBOARD_BALANCE_CHART](context, balance) {
        state.dashboardData.balance_chart = balance
    },

    [BANK_DEPOSIT](context, data) {
        state.bankDeposit = data
    },

    [ACTIVE_CURRENCY](context, data) {
        state.activeCurrency = data || 'USD'
    },

    [SET_ROBOT_AI](context, data) {
        state.robotIsActive = data
        localStorage.setItem('robotIsActive', data)
    },

    [SET_AUTOTRADER_CONFIG](context, data) {
        Vue.set(state,'autoTraderConfig', data)
    },

    [SET_BANKS_VARIABLES](context, data) {
        Vue.set(state, 'bankConfig', data)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
